

































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UPEIPsych1020A2Task2',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        initialPost: null,
      },
      questions: [
        {
          text: 'In the space below, please submit the post you have created with respect to the following instructions:',
          inputName: 'initialPost',
        },
      ],
      instructions: [
        {
          number: 0,
          text: 'Make an initial posting (minimum 500 words) about the videos including ALL of:',
        },
        {
          number: 1,
          text: 'Your detailed observations, thoughts, and ideas about the videos.',
        },
        {
          number: 2,
          text: 'How your ideas connect with the videos and course readings',
        },
        {
          number: 3,
          text: 'What the videos have in common and/or how they diverge and WHY.',
        },
        {
          number: 4,
          text: 'Any further questions that occurred to you while you were writing.',
        },
      ],
    };
  },
});
